import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../utils/common';

const imgIpad       = "/asset/img/ipad.png";
const imgIPhone     = "/asset/img/iphone-mockup.png";

class HowItWorks extends Component {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('HowItWorks.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <section>
                <div className="container">
                    <div className="row landing-image-text">
                    <div className="col-sm-6 col-sm-push-6"><img className="center-block" src={imgIpad} alt="iPad" /></div>
                    <div className="col-sm-6 col-sm-pull-6">
                        <h2 className="font-alt">We are your team!</h2>
                        <p>
                            We are your partners! We'll work closely with you to understand your business goals and develop
                            a web and/or mobile app that meets your specific needs. We'll also provide you with the ongoing support you need 
                            to keep your app up to date and running smoothly.
                            <br /><br />
                            Whether you're looking to launch your new idea, increase sales, improve customer engagement, 
                            or streamline your operations, we can help you build a web or mobile app that delivers results.
                        </p>
                        <p>
                            Flawless... Fantastic... Fast! 
                        </p>
                        <a className="btn btn-border-d btn-circle" href="#">Let's build your app!</a>
                    </div>
                    </div>
                    <div className="row landing-image-text">
                    <div id='divHowItWorks' className="col-sm-6"><img className="center-block" src={imgIPhone} alt="iPhone" /></div>
                    <div className="col-sm-6">
                        <h2 className="font-alt">How it works</h2>
                        <p className="font-serif">
                            Build your app in 3 simple steps
                        </p>
                        <ol>
                        <li>YOUR IDEA - Share with us your idea and vision.</li>
                        <li>OUR EXPERTISE - We'll do an in-depth analysis, design, develop and demo to you.</li>
                        <li>WE LAUNCH! We conquer the world together!</li>
                        </ol>
                        <Link to="/" className="btn btn-border-d btn-circle" onClick={() => scrollTo('divContactUs')}><i className='fa fa-calendar'></i> Set up an appointment</Link>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HowItWorks;
