import React, { Component } from 'react';

class Welcome extends Component {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('Welcome.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <section className="module-medium" id="about">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-8 col-sm-offset-2">
                        <h2 className="module-title font-alt">Welcome to InstantApp</h2>
                        <div className="module-subtitle font-serif large-text">
                            We’re a highly experienced Silicon Valley based software development agency, 
                            lovingly curating pixels and harnessing technology to build powerful apps.
                        <div className="text-center">
                            <div className="btn-group mt-30"><a className="btn btn-border-d btn-circle" href="#"><i className="fa fa-apple"></i> Apple Store</a><a className="btn btn-border-d btn-circle" href="#"><i className="fa fa-globe"></i> Web</a><a className="btn btn-border-d btn-circle" href="#"><i className="fa fa-android"></i> Play Store</a></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Welcome;
