import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../utils/common';

const imgWhyChoose  = "/asset/img/why_choose.png";

class WhyUs extends Component {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('WhyUs.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <section className="module pb-0 bg-dark landing-reason parallax-bg" data-background="asset/img/why_choose_bg.png">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-6"><img src={imgWhyChoose} alt="Why InstantApp is best!" /></div>
                    <div className="col-sm-6">
                        <h2 className="module-title font-alt align-left">Why go with InstantApp?</h2>
                        <p className="module-subtitle font-serif align-left">
                            Apps like rockets... built in warp speed!
                        </p>
                        <p>
                            We know how to build apps that rock the world and deliver the punches!
                        </p>
                        <p>
                            We've done it a number of times... we deliver them fast!
                        </p>
                        <p>
                            With us, you'll have your app in an instant!
                        </p>
                        {/* 
                        <a className="btn btn-border-w btn-round video-pop-up" href="https://www.youtube.com/watch?v=TTxZj3DZiIM" style={{marginBottom: '10px'}}><i className="icon-video"></i> Watch our video</a>
                        */}
                        <Link to="/" className="btn btn-border-w btn-round" style={{marginBottom: '10px'}} onClick={() => scrollTo('divContactUs')}><i className='fa fa-mobile'></i> Build My App!</Link>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhyUs;
