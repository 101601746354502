import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_POSTS } from '../store/adm/actions';
import { scrollTo } from '../utils/common';

const imgLogo  = "/asset/img/logo_header.png";

const Header = () => {
    
    const dispatch = useDispatch();
    
    //////////////////////
    // loadPosts()
    //////////////////////
    function loadPosts() {
        console.log('loadPosts()');
        var data = {
            app_id : 1,
        };

        dispatch({
            type    : GET_POSTS,
            payload : data,
        });
    }

    return (
        <nav className="navbar navbar-custom navbar-fixed-top " role="navigation">
            <div className="container">
            <div className="navbar-header">
                <button className="navbar-toggle" type="button" data-toggle="collapse" data-target="#custom-collapse"><span className="sr-only">Toggle navigation</span><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                {/* 
                imgLandscape
                <a className="navbar-brand" href="index.html">InstantApp</a>
                */}
                <a className="navbar-brand" href='/'><img src={imgLogo} /></a>
            </div>
            <div className="collapse navbar-collapse" id="custom-collapse">
                <ul className="nav navbar-nav navbar-right">
                    <li><Link to="/" onClick={() => scrollTo('home')}>HOME</Link></li>
                    <li><Link to="/" onClick={() => scrollTo('divFeatures')}>FEATURES</Link></li>
                    <li><Link to="/" onClick={() => scrollTo('divHowItWorks')}>HOW IT WORKS</Link></li>
                    <li><Link to="/" onClick={() => scrollTo('divPortfolio')}>PORTFOLIO</Link></li>
                    <li><Link to="/" onClick={() => scrollTo('divContactUs')}>CONTACT US</Link></li>
                </ul>
            </div>
            </div>
        </nav>
    );
};

export default Header;
