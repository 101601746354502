import React, { Component, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Project } from '../../types';


const Portfolio = () => {
    
    const dispatch = useDispatch();

    const [filter, setFilter] = useState('all');
    const [portfolio, setPortfolio] = useState<Project[]>([]);

    const alProject:Project[] = [
        {
            name        : 'Arashi Software',
            description : 'Japan-based Software Development Company that features uncluttered designs that focus on content which what matters most.',
            img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/arashisoftware.jpg',
            url         : 'https://portfolio.instantapp.dev/arashisoftware',
            category    : ['all', 'web'],
            filtered    : true,
        },
        {
            name        : 'Bratt / Jolie Wedding',
            description : 'Wedding website of wonderful couple Bratt and Jolie who are trying knot at the beautiful Central Park in New York, New York.',
            img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/brattjoliewedding.jpg',
            url         : 'https://portfolio.instantapp.dev/brattjoliewedding',
            category    : ['all', 'web', 'mar'],
            filtered    : true,
        },
        {
            name        : 'Expression Studio',
            description : 'Expression Studio is an art studio that has been a beacon for artists and art enthusiasts alike. It is where creativity knows no bounds.',
            img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/expression.jpg',
            url         : 'https://portfolio.instantapp.dev/expression',
            category    : ['all',  'web', 'mar'],
            filtered    : true,
        },
        {
            name        : 'Fresh Bean Cafe',
            description : 'Fresh Bean Cafe is is more than just a place to grab a cup. It is a haven for coffee enthusiasts and a hub for the community.',
            img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/freshbeancafe.jpg',
            url         : 'https://portfolio.instantapp.dev/freshbean',
            category    : ['all', 'web', 'mob'],
            filtered    : true,
        },
        {
            name        : 'Futura Architecture',
            description : 'It is an architecture service company who is passionate in creating spaces that inspire, engage and endure... where innovative design meets timeless architecture.',            
            img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/futura.jpg',
            url         : 'https://portfolio.instantapp.dev/futura',
            category    : ['all', 'des'],
            filtered    : true,
        },
        {
            name        : 'Olive Kitchen',
            description : 'This high-end restaurant creates a dining experience that transports patrons through the tantalizing tales of American cuisine.',
            img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/olivekitchen.jpg',
            url         : 'https://portfolio.instantapp.dev/olivekitchen',
            category    : ['all', 'web', 'mob'],
            filtered    : true,
        },
        {
          name        : 'Prestige Watch',
          description : 'Nestled in the heart of New York, this boutique is a haven for connoisseurs of luxury watches seeking the epitome of horological craftsmanship.',
          img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/prestige.jpg',
          url         : 'https://portfolio.instantapp.dev/prestigewatch',
          category    : ['all', 'web', 'mar'],
          filtered    : true,
        },
        {
          name        : 'WOW Travel',
          description : 'WOW Travel curates the perfect travel experience for its customers.',
          img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/wowtravel.jpg',
          url         : 'https://portfolio.instantapp.dev/wowtravel',
          category    : ['all', 'web', 'mob', 'mar'],
          filtered    : true,
        },
        {
          name        : 'McArthur Hosting',
          description : 'Highly-trusted and highly reliable hosting service provider based in Silicon Valley.',          
          img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/mcarthurhosting.jpg',
          url         : 'https://mcarthurhosting.com',
          category    : ['all', 'web'],
          filtered    : true,
        },
        {
          name        : 'McArthur Software',
          description : 'McArthur Software delivers business-aligned engineering services at a flexible scale for bespoke applications, products and platforms.',
          img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/mcarthursoftware.jpg',
          url         : 'https://mcarthursoftware.com',
          category    : ['all', 'web', 'mob'],
          filtered    : true,
        },
    ];
          

      useEffect(() => {
        setPortfolio(alProject);
      }, []);

      useEffect(() => {
        setPortfolio([]);
        const filtered = alProject.map(p => ({ ...p, filtered: p.category.includes(filter) }));
        setPortfolio(filtered);
      }, [filter]);
  
    
    return (
        <section className="xmodule pb-0" >
          <div className="xcontainer">
            <div className="row">
              <div className="col-sm-6 col-sm-offset-3">
                <h2 className="xmodule-title font-alt">Our Works</h2>
                <div className="xmodule-subtitle font-serif"></div>
              </div>
            </div>
          </div>
          <div className="xcontainer">
            <div className="row">
              <div className="col-sm-12">
                <ul className="filter font-alt" id="filters">
                    <li><Link to='/' className="wow {filter === 'all'? 'current': ''}" href="#" onClick={() => setFilter('all')}>All</Link></li>
                    <li><Link to='/' className="wow {filter === 'web'? 'current': ''}" href="#" onClick={() => setFilter('web')}>Web</Link></li>
                    <li><Link to='/' className="wow {filter === 'mob'? 'current': ''}" href="#" onClick={() => setFilter('mob')}>Mobile</Link></li>
                    <li><Link to='/' className="wow {filter === 'mar'? 'current': ''}" href="#" onClick={() => setFilter('mar')}>Marketing</Link></li>
                    <li><Link to='/' className="wow {filter === 'des'? 'current': ''}" href="#" onClick={() => setFilter('des')}>Design</Link></li>
                </ul>
              </div>
            </div>
          </div>

          <ul id='divPortfolio' role="list" className="tw-grid tw-grid-cols-4 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-4 sm:tw-gap-x-6 lg:tw-grid-cols-4 xl:tw-gap-x-8" style={{listStyleType: 'none'}}>
            {portfolio.map(item => item.filtered === true ? (
                <li key={item.img}>
                  <div className="tw-rounded-lg tw-bg-gray-100">
                    <a href={item.url} target='_new'>
                      <img src={item.img} alt="" className="tw-object-cover group-hover:tw-opacity-75" />
                      <h3 className="tw-mt-2 tw-block tw-truncate tw-text-gray-900">{item.name}</h3>
                      <p className="tw-pointer-events-none tw-block tw-text-base tw-font-medium tw-text-gray-500">{item.description}</p>
                    </a>
                  </div>
                </li>
              ) : undefined)}
          </ul>
      </section>
    );
};

export default Portfolio;
