import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from '../../utils/common';

const imgBanner = "/asset/img/banner_img.png";

class Landing extends Component {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('Landing.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <>
                <section id="home" className="home-section home-fade home-full-height bg-dark-60 landing-header"  data-background="asset/img/landing_bg.jpg">
                    <div className="titan-caption">
                        <div className="caption-content">
                            <div className="container">
                                <div className="font-alt mb-30 titan-title-size-4">We know apps!</div>
                                <div className="font-alt">
                                    ... and we build 'em fast!!!
                                    <br /><br />
                                    Get your business ahead with a cutting-edge mobile app!
                                </div>
                                <div className="font-alt mt-30">
                                    <Link to="/" className="btn btn-border-w btn-circle" onClick={() => scrollTo('divContactUs')}><i className="icon-download"></i> Free Consultation</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                    <div className="col-md-8 col-md-offset-2"><img className="img-responsive banner-img" src={imgBanner} /></div>
                    </div>
                </div>
            </>
        );
    }
}

export default Landing;
