import React, { Component } from 'react';

import LayoutMaster from '../../layout/LayoutMaster';

import Landing from './Landing';
import Welcome from './Welcome';
import WhyUs from './WhyUs';
import Features from './Features';
import CTANow from './CTANow';
import HowItWorks from './HowItWorks';
import Screenshots from './Screenshots';
import CTAWhyWait from './CTAWhyWait';
import Portfolio from './Portfolio';
import ContactUs from './ContactUs';
import Counter from '../../sandbox/Counter';

class HomeMain extends Component {

    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('ContactMain.componentDidMount()');
    } 

    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <LayoutMaster>
                <Landing />
                <Welcome />
                <WhyUs />
                <Features />
                <CTANow />
                <HowItWorks />
                <Portfolio />
                <ContactUs />
            </LayoutMaster>
        );
    }

}

export default HomeMain;
