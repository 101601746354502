import React, { Component } from 'react';

class CTANow extends Component {
    
    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('CTANow.componentDidMount()');
    } 

    
    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <section className="module-small free-trial">
                <div className="container text-center">
                    <div className="row">
                    <div className="col-sm-8 col-sm-offset-2">
                        <h2 className="font-alt">Let us &nbsp;<span className="color-golden">build your mobile app</span> now!</h2>
                        <p className="color-light font-15">
                            We'll help you turn your idea into a reality.<br />
                            We have a team of experienced mobile app developers who can take your app from concept to completion.
                        </p>
                    </div>
                    </div>
                    <div><a className="btn btn-warning btn-circle" href="#">Contact Us!</a></div>
                </div>
            </section>
        );
    }
}

export default CTANow;
